<template>
  <v-app id="inspire">
    <v-app id="inspire">
      <v-navigation-drawer
          v-model="drawer"
          app
          clipped
          class="style-drawer"
      >
        <v-list dense>
          <v-list-item link>
            <v-list-item-action>
              <v-icon class="tamanho-do-icone">mdi-home</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="navigateTo('home')">
              <v-list-item-title class="cor-letra">Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-action>
              <v-icon class="tamanho-do-icone">mdi-doctor</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="navigateTo('atendimentos')">
              <v-list-item-title class="cor-letra">Atendimentos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-action>
              <v-icon class="tamanho-do-icone">mdi-currency-usd</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="navigateTo('financeiro')">
              <v-list-item-title class="cor-letra">Financeiro</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <div class="pa-2">
            <v-btn class="estilo-botao" block>
              Sair
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>

      <v-app-bar
          class="cor-header"
          app
          clipped-left
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="cor-letra">Creative Learning</v-toolbar-title>
      </v-app-bar>

      <v-main>
        <v-container

            fluid
        >
          <v-row
              align="center"
              justify="center"
          >
            <v-col>
              <router-view></router-view>
            </v-col>
          </v-row>
        </v-container>
      </v-main>

      <v-footer app>
        <span>Schaffen it &copy; 2014 - {{ new Date().getFullYear() }}</span>
      </v-footer>
    </v-app>
  </v-app>
</template>

<script>
import colors from 'vuetify/lib/util/colors'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

export default {
  name: 'Admin',
  data: () => ({
    items: [
      {
        title: 'Aqui',
        icon: 'mdi-instagram'
      }
    ],
    drawer: true,
  }),
  created() {
    const vuetify = new Vuetify({
      theme: {
        themes: {
          light: {
            primary: '#3f51b5',
            secondary: '#b0bec5',
            accent: '#8c9eff',
            error: '#b71c1c',
          },
        },
      },
    })
    this.$vuetify.theme.light = true
  },
  methods: {
    navigateTo(where) {
      this.$router.push({path: where})
    }
  }
}
</script>

<style>
.v-toolbar__content, .v-toolbar__extension {
  height: 56px !important;
}

.theme--light.v-icon {
  color: #eeeeee;
}

.estilo-botao{
  color: white !important;
  background-color: #ef4056 !important;
}

.cor-header > div > button > span > i {
  color: white !important;
}

.cor-letra{
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.445);
}

.cor-header{
  background-color: #ea5c2d !important;
}

.style-drawer{
  background-color: #98a956 !important;
  color: white;
}

.tamanho-do-icone {
  font-size: 50px !important;
}
</style>

<template>
  <v-app id="inspire">
    <v-app id="inspire">
      <v-navigation-drawer
          v-model="drawer"
          app
          clipped
          class="style-drawer"
      >
        <v-list dense>
          <v-list-item link>
            <v-list-item-action>
              <v-icon class="tamanho-do-icone">mdi-book</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="navigateTo('banners')">
              <v-list-item-title class="cor-letra tamanho-menu">Banners</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-action>
              <v-icon class="tamanho-do-icone">mdi-book-open</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="navigateTo('diferenciais')">
              <v-list-item-title class="cor-letra tamanho-menu">Diferenciais</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-action>
              <v-icon class="tamanho-do-icone">mdi-image-multiple</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="navigateTo('albuns')">
              <v-list-item-title class="cor-letra tamanho-menu">Álbuns</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-action>
              <v-icon class="tamanho-do-icone">mdi-doctor</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="navigateTo('atendimentos')">
              <v-list-item-title class="cor-letra tamanho-menu">Atendimentos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-action>
              <v-icon class="tamanho-do-icone">mdi-chat</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="navigateTo('comunicados')">
              <v-list-item-title class="cor-letra tamanho-menu">Comunicados</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-action>
              <v-icon class="tamanho-do-icone">mdi-calendar-blank-multiple</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="navigateTo('calendario')">
              <v-list-item-title class="cor-letra tamanho-menu">Calendário</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-action>
              <v-icon class="tamanho-do-icone">mdi-silverware</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="navigateTo('cardapio')">
              <v-list-item-title class="cor-letra tamanho-menu">Cardápio</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-action>
              <v-icon class="tamanho-do-icone">mdi-account</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="navigateTo('usuario')">
              <v-list-item-title class="cor-letra tamanho-menu">Usuários</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <div class="pa-2">
              <v-btn class="estilo-botao" block @click="navigateTo('login')">
                Sair
              </v-btn>
          </div>
        </template>
      </v-navigation-drawer>

      <v-app-bar
          class="cor-header"
          app
          clipped-left
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="cor-letra">Creative Learning - Admin</v-toolbar-title>
      </v-app-bar>

      <v-main>
        <v-container

            fluid
        >
          <v-row
              align="center"
              justify="center"
          >
            <v-col>
              <router-view></router-view>
            </v-col>
          </v-row>
        </v-container>
      </v-main>

      <v-footer app>
        <span>Schaffen it &copy; 2014 - {{ new Date().getFullYear() }}</span>
      </v-footer>
    </v-app>
  </v-app>
</template>

<script>
import colors from 'vuetify/lib/util/colors'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

export default {
  name: 'Admin',
  data: () => ({
    items: [
      {
        title: 'Aqui',
        icon: 'mdi-instagram'
      }
    ],
    drawer: true,
  }),
  created() {
    const vuetify = new Vuetify({
      theme: {
        themes: {
          light: {
            primary: '#3f51b5',
            secondary: '#b0bec5',
            accent: '#8c9eff',
            error: '#b71c1c',
          },
        },
      },
    })
    this.$vuetify.theme.light = true
  },
  methods: {
    navigateTo(where) {
        if(this.$router.history.current.path != ('/' + where)){
        this.$router.push({path: where})
        }
    }
  }
}
</script>

<style>
.v-toolbar__content, .v-toolbar__extension {
  height: 56px !important;
}

.theme--light.v-icon {
  color: #eeeeee;
}

.estilo-botao{
  color: white !important;
  background-color: #ef4056 !important;
}

.cor-header > div > button > span > i {
  color: white !important;
}

.cor-letra{
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.445);
}

.cor-header{
  background-color: #ea5c2d !important;
}

.style-drawer{
  background-color: #98a956 !important;
  color: white;
}

.tamanho-menu{
  font-size: 18px !important;
  padding: 5px;
}

.tamanho-do-icone {
  font-size: 50px !important;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.445);
}

.container--fluid{
  width: 85%;
  margin: auto;
}

th.text-start{
  background-color: #ea5c2d;
}

th.text-start > span{
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.445);
}

.adiciona-button {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tam {
  width: 100%;
  display: flex;
  justify-content: end;
}

.icon_acoes{
  color: #ef4056 !important;
}

.icon_acoes:hover{
  color: #74202b !important;
}

/*
verde: #98C238 #98a956;
vermelho: #ef4056 #ef4056;
amarelo: #F4BB2B #ea5c2d
*/

</style>

